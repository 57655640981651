import React from 'react'
import styled, { css } from 'styled-components'

const StyledBox = styled.div`
  position: relative;
  font-size: 20px;
  font-weight: 300;
  background-color: transparent;
  color: ${(props) => props.theme.color.text.main};
  border: 2px solid ${(props) => props.theme.color.main};
  padding: 15px 15px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 21px;
    position: absolute;
    right: -10px;
    bottom: -11px;

    transform: rotate(45deg);
    background: #fff;
    border-left: 2px solid #1c1c1c;
  }

  ${(props) =>
    props.white &&
    css`
      border-color: #fff;
      color: #fff;
    `};

  ${(props) =>
    props.contrast &&
    css`
      overflow: unset;
      border-color: ${props.theme.color.contrast};
      color: ${props.theme.color.contrast};
      background: ${props.theme.color.greyAlt2};

      &::after {
        border-color: ${props.theme.color.contrast};
      }
    `};

  ${(props) =>
    props.noCorner &&
    css`
      &::after {
        display: none !important;
      }
    `};
`

function BoxDefaultOutline({ children, noCorner, contrast, white, ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledBox noCorner={noCorner} contrast={contrast} white={white} {...rest}>
      {children}
    </StyledBox>
  )
}

export default BoxDefaultOutline
