import Plaatjie from '@ubo/plaatjie'
import React from 'react'
import styled, { css } from 'styled-components'
import ParseContent from '../shared/ParseContent'

const VideoQuote = styled.div`
  color: #fff;
  position: relative;

  blockquote p {
    text-align: justify;
  }
  overflow: visible;

  ${(props) =>
    props.backgroundPosition === 'behindvideo'
      ? css`
          background-color: #d50021;
          @media (min-width: 992px) {
            margin-top: -11rem;
            padding-top: 10rem;
            padding-bottom: 3rem;
          }
        `
      : css`
          .bg-applier {
            background-color: rgba(0, 0, 0, 0.6);
            position: relative;

            @media (min-width: 992px) {
              margin-top: -11rem;
              padding-top: 10rem;
              padding-bottom: 2rem;
              background-color: rgba(0, 0, 0, 0.6);
            }

            @media (max-width: 991.98px) {
              background-color: #000;
            }
          }
        `}

  & p {
    position: relative;
    z-index: 2;
    font-style: italic;
    font-weight: bold;
    padding-right: 1rem;
  }

  @media (min-width: 992px) {
    & p {
      padding-left: 6rem;
      font-size: 26px;
    }

    & p:last-child {
      font-size: 20px !important;
      font-weight: 400 !important;
      ${(props) =>
        props.backgroundPosition === 'behindvideo'
          ? css`
              color: #fff !important;
            `
          : css`
              color: #d50021 !important;
            `}
    }

    & p:first-child {
      font-size: 26px !important;
      font-weight: 700 !important;
    }

    & svg {
      position: absolute !important;
      top: -5.5rem;
      left: 3rem;
      z-index: 2;
    }
  }

  @media (max-width: 991px) {
    margin-top: -8rem;
    padding-top: 2rem;

    & p {
      font-size: 20px;
    }

    & svg {
      width: 60px;
    }
  }
`

const VideoSection = styled.section`
  position: relative;
  overflow: visible;

  &:has(.no-quote-bg) {
    @media (min-width: 992px) {
      padding-bottom: 10rem;
      padding-top: 3rem;
    }
  }
`

const VideoBG = styled(Plaatjie)`
  height: 100%;

  ${(props) =>
    props.backgroundPosition === 'behindvideo'
      ? css`
          @media (min-width: 992px) {
            top: -7rem !important;
            & img {
              object-fit: contain !important;
              height: 150% !important;
            }
          }
        `
      : css`
          & img {
            width: 70% !important;
            height: 150% !important;
            object-fit: contain !important;
            object-position: bottom !important;
            left: -10% !important;
            bottom: 20% !important;
          }
        `}

  @media (max-width: 991px) {
    height: 400px !important;
  }
  padding-top: 3rem;
`

const Iframe = styled.iframe`
  position: relative;
  z-index: 2;
  max-width: 820px;
  border: none;

  @media (min-width: 992px) {
    ${(props) =>
      !props.hasQuote &&
      css`
        margin-top: 12rem;
      `}
  }

  @media (max-width: 991px) {
    ${(props) =>
      props.backgroundPosition === 'behindvideo' &&
      css`
        margin-top: 10rem;
      `}
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    height: 399px !important;
  }

  @media (max-width: 767px) {
    height: 289px !important;
  }
`

const NoQuoteBG = styled(Plaatjie)`
  position: absolute !important;
  max-height: 100%;
  overflow: visible !important;
  scale: 1.2;

  img {
    object-fit: contain !important;
  }
`

function QuoteVideo({ video }) {
  return (
    <VideoSection className="position-relative">
      {video.backgroundImage && video.backgroundPosition === 'behindvideo' && (
        <VideoBG
          image={video.backgroundImage}
          className="position-absolute"
          backgroundPosition={video.backgroundPosition}
        />
      )}

      {!video.quote && video.vimeoUrl && video.backgroundImage && (
        <NoQuoteBG image={video.backgroundImage} className="no-quote-bg" />
      )}

      <div className="position-relative container py-5">
        <div className="row align-items-center py-lg-3">
          <div className="col-lg-9 mx-auto">
            {video.vimeoUrl && (
              <Iframe
                hasQuote={video.quote}
                src={video.vimeoUrl}
                width="100%"
                height="470"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            )}
          </div>
        </div>
      </div>
      {video.quote && (
        <VideoQuote
          className="position-relative"
          backgroundPosition={video.backgroundPosition}
        >
          {video.backgroundImage &&
            video.backgroundPosition === 'behindtext' && (
              <VideoBG
                image={video.backgroundImage}
                className="position-absolute"
                backgroundPosition={video.backgroundPosition}
              />
            )}
          <div className="bg-applier">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 mx-auto position-relative">
                  <svg
                    width="133"
                    height="117"
                    viewBox="0 0 133 117"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill={
                        video.backgroundImage &&
                        video.backgroundPosition === 'behindtext'
                          ? '#D50021'
                          : '#000'
                      }
                      d="M0.794923 75.5C0.794923 54.5 1.96159 38.8333 4.29492 28.5C6.96159 18.1666 12.1283 10.8333 19.7949 6.49999C27.4616 2.16666 39.4616 -1.16825e-05 55.7949 -1.16825e-05V22.5C45.7949 22.5 39.2949 24.8333 36.2949 29.5C33.6283 34.1667 32.2949 43.5 32.2949 57.5V65H51.7949V117H0.794923V75.5ZM76.7949 75.5C76.7949 54.5 78.1283 38.8333 80.7949 28.5C83.4616 18.1666 88.6283 10.8333 96.2949 6.49999C103.962 2.16666 115.962 -1.16825e-05 132.295 -1.16825e-05V22.5C122.295 22.5 115.795 24.8333 112.795 29.5C109.795 34.1667 108.295 43.5 108.295 57.5V65H127.795V117H76.7949V75.5Z"
                    />
                  </svg>

                  {video.quote && <ParseContent content={video.quote} />}
                </div>
              </div>
            </div>
          </div>
        </VideoQuote>
      )}
    </VideoSection>
  )
}

export default QuoteVideo
