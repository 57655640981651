/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ProjectCard from 'components/elements/ProjectCard'

const Projecten = ({
  notId,
  categoryIds,
  woningbouwIds,
  renovatieIds,
  zakelijkIds,
  particulierIds,
  diversenIds,
  projectIds,
  limit,
}) => {
  const { projects } = useStaticQuery(graphql`
    {
      projects: allWpProject {
        edges {
          node {
            databaseId
            id
            title
            uri
            projectrecap {
              locatie
              banner {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 450, height: 320, quality: 100)
                  }
                }
              }
              livestream {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 255, quality: 100)
                    }
                  }
                }
                iframe
              }
            }

            categories {
              nodes {
                id
                slug
              }
            }
            woningbouw {
              nodes {
                id
              }
            }
            zakelijk {
              nodes {
                id
                name
              }
            }
            particulier {
              nodes {
                id
                name
              }
            }
            diversen {
              nodes {
                id
                name
              }
            }
            renovatie {
              nodes {
                id
              }
            }
          }
        }
      }
    }
  `)

  let projectPosts = projects.edges

  if (projectIds && projectIds.length > 0) {
    projectPosts = projectIds
  }

  if (categoryIds && categoryIds.length > 0) {
    projectPosts = projectPosts.filter(({ node: { categories } }) => {
      let response = false

      if (categories && categories.nodes.length > 0) {
        categories.nodes.forEach((category) => {
          if (categoryIds.indexOf(category.id) !== -1) {
            response = true
          }
        })
      }

      return response
    })
  }

  if (woningbouwIds && woningbouwIds.length > 0) {
    projectPosts = projectPosts.filter(({ node: { woningbouw } }) => {
      let response = false

      if (woningbouw && woningbouw.nodes.length > 0) {
        woningbouw.nodes.forEach((category) => {
          if (woningbouwIds.indexOf(category.id) !== -1) {
            response = true
          }
        })
      }

      return response
    })
  }

  if (zakelijkIds && zakelijkIds.length > 0) {
    projectPosts = projectPosts.filter(({ node: { zakelijk } }) => {
      let response = false

      if (zakelijk && zakelijk.nodes.length > 0) {
        zakelijk.nodes.forEach((category) => {
          if (zakelijkIds.indexOf(category.id) !== -1) {
            response = true
          }
        })
      }

      return response
    })
  }

  if (particulierIds && particulierIds.length > 0) {
    projectPosts = projectPosts.filter(({ node: { particulier } }) => {
      let response = false

      if (particulier && particulier.nodes.length > 0) {
        particulier.nodes.forEach((category) => {
          if (particulierIds.indexOf(category.id) !== -1) {
            response = true
          }
        })
      }

      return response
    })
  }

  if (diversenIds && diversenIds.length > 0) {
    projectPosts = projectPosts.filter(({ node: { diversen } }) => {
      let response = false

      if (diversen && diversen.nodes.length > 0) {
        diversen.nodes.forEach((category) => {
          if (diversenIds.indexOf(category.id) !== -1) {
            response = true
          }
        })
      }

      return response
    })
  }

  if (renovatieIds && renovatieIds.length > 0) {
    projectPosts = projectPosts.filter(({ node: { renovatie } }) => {
      let response = false

      if (renovatie && renovatie.nodes.length > 0) {
        renovatie.nodes.forEach((category) => {
          if (renovatieIds.indexOf(category.id) !== -1) {
            response = true
          }
        })
      }

      return response
    })
  }

  if (notId) {
    projectPosts = projectPosts.filter(
      ({ node: { databaseId } }) => databaseId !== notId
    )
  }

  if (projectPosts.length === 0) {
    return (
      <div className="text-center col-12 pb-5">
        Geen gerelateerde projecten gevonden
      </div>
    )
  }

  if (limit && limit > 0) {
    projectPosts = projectPosts.sort(() => Math.random() - 0.5).slice(0, limit)
  }

  return projectIds && projectIds.length > 0
    ? projectPosts.map((project) => (
        <div key={project.databaseId} className="col-lg-6 px-lg-5">
          <ProjectCard
            src={project.projectrecap.banner}
            livestream={project.projectrecap.livestream}
            location={project.projectrecap.locatie}
            title={project.title}
            offset
            link={{
              title: 'Bekijk project >',
              to: project.uri,
            }}
          />
        </div>
      ))
    : projectPosts.map(({ node: project }) => (
        <div key={project.databaseId} className="col-lg-6 px-lg-5">
          <ProjectCard
            src={project.projectrecap.banner}
            livestream={project.projectrecap.livestream}
            location={project.projectrecap.locatie}
            title={project.title}
            offset
            link={{
              title: 'Bekijk project >',
              to: project.uri,
            }}
          />
        </div>
      ))
}

export default Projecten
