import React from 'react'

import styled from 'styled-components'
import { Link } from 'gatsby'
import ChevronRightIcon from '../../img/icons/chevron-right-red.svg'
import Projecten from '../Projecten'

const ProjectenSection = styled.section`
  padding-block: 3rem;
  & h2 {
    color: #d8d8d8;
    font-weight: bold;
  }

  & .title a {
    font-weight: bold;
    &::before {
      rotate: 180deg;
      content: '';
      display: block;
      background-image: url(${ChevronRightIcon});
      background-size: contain;
      width: 14px;
      height: 18px;
      background-repeat: no-repeat;
      margin-left: 5px;
      margin-right: 10px;
      transform: rotate(180deg);
    }
  }

  @media (min-width: 992px) {
    .projecten-wrapper {
      position: relative;
      margin-top: -4.5rem;
      z-index: 2;
    }

    & h2 {
      font-size: 250px;
    }

    .title a {
      margin-top: 5rem;
    }
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: 150px;
    }

    .title {
      padding-inline: 1rem;
    }

    .title a {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 575.98px) {
    & h2 {
      font-size: 80px;
    }
  }
`

function Projects({ projects }) {
  return (
    <ProjectenSection className="container">
      <div className="row">
        <div className="title d-flex flex-column flex-lg-row justify-content-between items-center w-100">
          <h2 className="mr-3 mb-0">{projects.title}</h2>
          {projects.link && (
            <Link
              className="d-flex align-items-center mr-lg-5"
              to={projects.link.url}
            >
              {projects.link.title}
            </Link>
          )}
        </div>
      </div>

      <div className="projecten-wrapper row mb-5">
        <Projecten
          projectIds={projects.projects ? projects.projects : projects.project}
          limit={2}
        />
      </div>
    </ProjectenSection>
  )
}

export default Projects
