import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'
import BoxDefault from './BoxDefault'
import ChevronRightIcon from '../../img/icons/chevron-right-red.svg'

const Concepten = styled.div`
  ul {
    list-style: none;

    li a {
      font-weight: bold !important;

      &:hover {
        color: #d50032 !important;
      }
    }
  }

  & h2 {
    text-align: right;
    color: #d8d8d8;
    font-weight: bold;
  }

  & h3 {
    font-weight: bold;
  }

  .row {
    position: relative;
    z-index: 2;
  }

  .col-left {
    position: relative;

    @media (min-width: 992px) {
      top: -5rem;
    }
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: 250px;
    }
    margin-bottom: 3rem;

    .col-right {
      margin-left: -3rem;
    }

    & ul {
      padding-left: 0;
    }
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: 150px;
    }

    ul {
      padding-left: 0.1rem;

      li {
        padding-block: 0.25rem;
        font-size: 16px;
      }
    }

    margin-bottom: 3rem;
  }

  @media (max-width: 575.98px) {
    ul {
      font-size: 14px;
    }

    .col-right {
      margin-top: 30px;
    }
  }

  @media (max-width: 575.98px) {
    & h2 {
      font-size: 80px;
    }
  }
`

const LinksBlockLeft = styled(BoxDefault)`
  background-color: #000;

  width: fit-content;

  @media (min-width: 992px) {
    margin-left: -4rem;
    margin-top: -6rem;
  }

  @media (max-width: 991.98px) {
    margin-top: -4rem;
  }

  &:after {
    border-top-width: 40px;
    border-right-width: 40px;
  }

  & a {
    display: flex;
    align-items: center;
    position: relative;
    color: #fff !important;
    padding-left: 0 !important;
    padding-right: 15px !important;

    &::before {
      rotate: 180deg;
      content: '';
      display: block;
      background-image: url(${ChevronRightIcon});
      background-size: contain;
      width: 14px;
      height: 18px;
      background-repeat: no-repeat;
      margin-left: 5px;
      margin-right: 20px;
      transform: rotate(180deg);
    }
  }

  @media (min-width: 576px) {
    padding: 3rem;
  }

  @media (max-width: 575.98px) {
    padding: 2rem;
  }
`

const LinksBlockRight = styled(BoxDefault)`
  background-color: #000;
  width: fit-content;

  @media (min-width: 992px) {
    margin-top: -6rem;
  }

  @media (max-width: 991.98px) {
    margin-top: -4rem;
  }

  &:after {
    right: unset;
    left: 0;
    rotate: 90deg;
    border-top-width: 40px;
    border-right-width: 40px;
  }

  & a {
    display: flex;
    align-items: center;
    position: relative;
    color: #fff !important;
    padding-left: 0 !important;
    padding-right: 15px !important;

    &::before {
      rotate: 180deg;
      content: '';
      display: block;
      background-image: url(${ChevronRightIcon});
      background-size: contain;
      width: 14px;
      height: 18px;
      background-repeat: no-repeat;
      margin-left: 5px;
      margin-right: 20px;
      transform: rotate(180deg);
    }
  }

  @media (min-width: 992px) {
    margin-right: -4rem;
  }

  @media (min-width: 576px) {
    padding: 3rem;
  }

  @media (max-width: 575.98px) {
    padding: 2rem;
  }
`

const ImageWrapperLeft = styled.div`
  position: relative;

  h3 {
    font-family: ${(props) => props.theme.font.family.secondary};
    position: absolute;
    bottom: 15%;
    left: 10%;
    display: block;
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    h3 {
      font-size: 100px;
    }
  }

  @media (max-width: 991px) {
    h3 {
      font-size: 75px;
    }
  }
`

const ImageWrapperRight = styled.div`
  position: relative;

  h3 {
    font-family: ${(props) => props.theme.font.family.secondary};
    position: absolute;
    bottom: 15%;
    right: 5%;
    display: block;
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    h3 {
      font-size: 100px;
    }
  }

  @media (max-width: 991px) {
    h3 {
      font-size: 75px;
    }
  }
`

function Concepts({ title, blockLeft, blockRight }) {
  if (!title || !blockLeft || !blockRight) return null
  if (blockLeft.links.length < 1 || blockRight.links.length < 1) return null

  return (
    <Concepten>
      <div className="container">
        <div className="d-flex justify-content-end w-full">
          {title && <h2>{title}</h2>}
        </div>
        <div className="row">
          <div className="col-lg-6 col-left">
            <div className="d-flex flex-column">
              <ImageWrapperLeft>
                {blockLeft.image && <Plaatjie image={blockLeft.image} />}
                {blockLeft.title && <h3>{blockLeft.title}</h3>}
              </ImageWrapperLeft>
              <LinksBlockLeft>
                <ul>
                  {blockLeft.links &&
                    blockLeft.links.map((edge) => (
                      <li key={edge.link.title}>
                        <Link to={edge.link.url}>{edge.link.title}</Link>
                      </li>
                    ))}
                </ul>
              </LinksBlockLeft>
            </div>
          </div>
          <div className="col-lg-6 col-right">
            <div className="d-flex flex-column align-items-end">
              <ImageWrapperRight>
                {blockRight.image && <Plaatjie image={blockRight.image} />}
                {blockRight.title && <h3>{blockRight.title}</h3>}
              </ImageWrapperRight>
              <LinksBlockRight>
                <ul>
                  {blockRight.links &&
                    blockRight.links.map((edge) => (
                      <li key={edge.link.title}>
                        <Link to={edge.link.url}>{edge.link.title}</Link>
                      </li>
                    ))}
                </ul>
              </LinksBlockRight>
            </div>
          </div>
        </div>
      </div>
    </Concepten>
  )
}

export default Concepts
